`<template>
  <PageContainer
    :title="$translations.referrals.title"
    :action="close"
  >
    <template v-slot:content>
      <iframe :src="iframeUrl" class="iframe"></iframe>
    </template>
  </PageContainer>
</template>

<script>
import { Settings, PageContainer } from '@seliaco/red-panda'

export default {
  name: 'Referrals',
  components: { PageContainer },
  data () {
    return {
      iframeUrl: null,
      close: {
        callback: () => this.$router.push({ name: 'Home' }),
        icon: 'icon-close'
      }
    }
  },
  mounted () {
    this.getContent()
  },
  methods: {
    getContent () {
      Settings.get('SELIA_USERS_REFERRALS_URL')
        .then((response) => {
          const userId = process.env.NODE_ENV === 'production'
            ? this.$store.getters['auth/user'].id
            : 'b1fb651f-7f87-473b-b2ec-cfbf34f4fe3e'
          this.iframeUrl = response.parse_value.replaceAll('{USER_ID}', userId)
        })
        .catch((e) => {
          this.$toast({
            text: e.message || this.$translations.error.default,
            severity: 'error'
          })
        })
    }
  }
}
</script>

<style lang="sass" scoped>
.iframe
  width: 100%
  height: 100vh
</style>
